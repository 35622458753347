import React, { useState } from "react";
import axios from "axios";
import "./globalStyles/style.scss";
import VideoList from "./components/VideoList";
import Form from "./components/Form";
import Header from "./components/Header";
import { Link } from "react-router-dom";
const App = () => {
  const [videos, setVideos] = useState([]);

  return (
    <>
      <Header />
      <div className="flex items-start gap-4 mt-[100px]">
        <Form setVideos={setVideos} />
        <VideoList videos={videos} />
      </div>
      <p className="w-full flex items-center fixed  justify-center text-center font-medium text-lg">
        This tool uses a search engine from
        <Link
          target="_blank"
          className="ml-2 mr-2 text-blue-400 underline"
          to="https://github.com/omegalabsinc"
        >
          omegatron
        </Link>
        tools
      </p>
    </>
  );
};

export default App;
