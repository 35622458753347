import Logo from "../assets/Ativo_39bg_newls (1).webp";
import React from "react";
import "../globalStyles/style.scss";
function Header() {
  return (
    <header className="black-header z-[99999]">
      <img className="logo" src={Logo} alt="logo" width="200px" height="20px" />
    </header>
  );
}

export default Header;
