import React, { useState } from "react";
import axios from "axios";

const Form = ({ setVideos }) => {
  const [prompt, setPrompt] = useState("");
  const [imageFiles, setImageFiles] = useState([]);
  const [videoFiles, setVideoFiles] = useState([]);
  const [audioFiles, setAudioFiles] = useState([]);
  const [fetch, setFetch] = useState(false);
  const handleImageChange = (e) => {
    setImageFiles(e.target.files);
  };

  const handleVideoChange = (e) => {
    setVideoFiles(e.target.files);
  };

  const handleAudioChange = (e) => {
    setAudioFiles(e.target.files);
  };

  const handleSubmit = async (e) => {
    setFetch(true);
    setVideos([]);
    e.preventDefault();
    const formData = new FormData();
    formData.append("prompt", prompt);
    formData.append("modalityType", "");
    for (let i = 0; i < imageFiles.length; i++) {
      formData.append("image_files", imageFiles[i]);
    }
    for (let i = 0; i < videoFiles.length; i++) {
      formData.append("video_files", videoFiles[i]);
    }
    for (let i = 0; i < audioFiles.length; i++) {
      formData.append("audio_files", audioFiles[i]);
    }

    try {
      const response = await axios.post(
        "https://dev-api.omegatron.ai/multimodality_search",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      );

      const videoDetailsPromises = response.data.map((item) =>
        axios.get(`https://www.googleapis.com/youtube/v3/videos`, {
          params: {
            id: item.youtube_id,
            key: "AIzaSyCk84-i_RX2tUW5KMWgiGmxu70gGF5tFEY",
            part: "snippet"
          }
        })
      );

      const videoDetailsResponses = await Promise.all(videoDetailsPromises);

      const videoData = videoDetailsResponses
        .map((response) => {
          console.log("response.data", response.data);
          if (response.data.items && response.data.items.length > 0) {
            const snippet = response.data.items[0].snippet;
            return {
              id: response.data.items[0].id,
              title: snippet.title,
              description: snippet.description,
              thumbnail: snippet.thumbnails.default.url
            };
          }
          return null;
        })
        .filter((item) => item !== null); // Remove any null entries

      console.log("setVideos", setVideos);
      setVideos(videoData);
      setFetch(false);
    } catch (error) {
      console.error("Error uploading files:", error);
    }
  };

  const navigateToKinera = () => {
    window.open("https://alpha.kinera.network/custom-tools");
  };

  return (
    <div className="flex flex-col h-full gap-4 items-start justify-between">
      <div>
        <h1 className="title">Dataset Search</h1>
        <form
          onSubmit={handleSubmit}
          className="flex p-2 w-fit flex-col gap-4 form-container"
        >
          <div className="label-container">
            <label>Prompt:</label>
            <input
              type="text"
              placeholder="Write your prompt"
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
            />
          </div>
          <div>
            <label>Search by Images:</label>
            <input
              type="file"
              multiple
              accept="image/*"
              onChange={handleImageChange}
            />
          </div>

          <div>
            <label>Search by Audio:</label>
            <input
              type="file"
              multiple
              accept="audio/*"
              onChange={handleAudioChange}
            />
          </div>
          <div>
            <label>Search by Video:</label>
            <input
              type="file"
              multiple
              accept="video/mp4,video/x-m4v,video/*"
              onChange={handleVideoChange}
            />
          </div>
          {!fetch ? (
            <button
              className="primary-button text-center flex items-center justify-center "
              type="submit"
            >
              Search Now
            </button>
          ) : (
            <button
              className="primary-button text-center flex items-center justify-center "
              type="submit"
            >
              Loading ...
            </button>
          )}
        </form>
      </div>
      <div className="flex items-end w-full gap-4 justify-end">
        <button
          onClick={navigateToKinera}
          className="subtle-button text-center flex items-center justify-center "
          type="submit"
        >
          Kinera tools
        </button>
      </div>
    </div>
  );
};

export default Form;
