// src/VideoList.js
import React from "react";
import "../globalStyles/style.scss";
const VideoList = ({ videos }) => {
  return (
    <div className="video-list-component">
      {videos.map((video) => (
        <div key={video.id} className="video-item">
          <iframe
            width="560"
            height="315"
            src={`https://www.youtube.com/embed/${video.id}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title={video.title}
          ></iframe>
          <h3>{video.title}</h3>
        </div>
      ))}
    </div>
  );
};

export default VideoList;
